import React from "react";
import ReactPlayer from "react-player";
import fichaDeProjeto from "../../docs/STEP_FALL_RISE_Ficha de Projeto.pdf";

export default function StepFallRise() {
  return (
    <div className="text-center">
      <div className="flex flex-col m-20 justify-center items-center">
        <h1 className="text-golden text-4xl py-4">Step Fall and Rise</h1>

        <a
          href={fichaDeProjeto}
          target="_blank"
          rel="noopener noreferrer"
          className="py-2 px-2 rounded mb-10 text-gray-200 font-poiret cursor-pointer  bg-golden hover:border-transparent focus:outline-none shadow-xl"
        >
          FICHA DE PROJETO
        </a>

        <p className="leading-relaxed text-golden mb-4 text-xl mt-6">
          <a
            href="https://step-fall-rise.com/"
            target="_blank"
            rel="noreferrer"
          >
            STEP, FALL and RISE{" "}
          </a>
          is a dance piece, created both for the stage and the screen.
        </p>
        <p className="leading-relaxed text-golden mb-10 text-xl">
          To watch the dance online please click{" "}
          <a
            href="https://step-fall-rise.com/"
            className="text-golden underline"
            rel="noreferrer"
            target="_blank"
          >
            here
          </a>
          .
        </p>
        <p className="leading-relaxed text-2xl mt-20">
          This piece investigates how human beings are able to persevere against
          life's fatalities and the inevitable death.
        </p>
        <p className="leading-relaxed text-3xl">
          Humans dare to dream and search for happiness inside a body that grows
          old,
          <br />
        </p>
        <p className="leading-relaxed text-4xl">
          gets sick and suffers from losses and disappointments.
        </p>
        <div className="py-40">
          <ReactPlayer
            url="https://stream.mux.com/8tcfedPRDLST3XPVTG1b9dvwNAzkLxjPBrG2Y9JmGB8.m3u8"
            controls
            width="100%"
            height="100%"
          />
        </div>
        <p className="py-4">
          This project is supported by Garantir Cultura, Oeiras Dance Academy,
          Arte Institute, RHI, Camara Municipal de Torres Vedras e Teatro Cine
          de Torres Vedras.
        </p>
        <div className="leading-10 py-10">
          <p>
            <bold>Performers: </bold>
            Miguel Nogueira, Ana Manneti, Rita Gaspar, Mariana Romão, Hayley
            Walker, Jonathan Taylor
          </p>
          <p>
            <bold>Choreography: </bold>Diana Seabra
          </p>
          <p>
            <bold>Music: </bold>
            John Cage & Merce Cunningham, rRoxymore, Demdike Stare, Tzusing,
            Anonymous & Meredith Monk
          </p>
          <p>
            <bold>Costumes: </bold> Diana Seabra
          </p>
          <p>
            <bold>Videography: </bold> PAGÁRRENDA
          </p>
          <p>
            <bold>Lighting Designer: </bold> Stageplot
          </p>
          <p>
            <bold>Premiere: </bold> Online: February 1, 2022
          </p>
        </div>
        <h3 className="py-2 text-golden text-2xl mt-10 mb-10">
          This project was supported by
        </h3>
        <div className="flex flex-wrap justify-center">
          <img
            src="https://res.cloudinary.com/umuv/image/upload/v1673345949/Sublime%20Dance%20Company/logos/Modelos-3Barras_cofianciado_por_FEDER_2_-1.png"
            alt="compete2020-portugal2020-uniao-europea-fundo-europeu-de-desenvolvimento-regional"
            // className="object-scale-down h-48 w-96"
          />
        </div>
        <h2 className="py-2 text-golden text-3xl m-20">AND</h2>
        <div className="flex flex-wrap justify-center">
          <img
            src="https://res.cloudinary.com/umuv/image/upload/v1636501690/Sublime%20Dance%20Company/REPERTORY/StepFallRise/Logo_Garantir_Cultura_-_Juntos_Criamos_MC.png"
            alt="support"
            className="object-scale-down h-48 w-96"
          />
          <img
            src="https://res.cloudinary.com/umuv/image/upload/v1636500523/Sublime%20Dance%20Company/REPERTORY/StepFallRise/AI_NEW_LOGO_FILES_RGB-01.png"
            alt="support"
            className="object-scale-down h-48 w-96"
          />
          <img
            src="https://res.cloudinary.com/umuv/image/upload/v1636500612/Sublime%20Dance%20Company/REPERTORY/StepFallRise/RHI_Logo_Black.png"
            alt="support"
            className="object-scale-down h-48 w-96"
          />
          <img
            src="https://res.cloudinary.com/umuv/image/upload/v1636500871/Sublime%20Dance%20Company/REPERTORY/StepFallRise/logo_oeiras_dance_academy.png"
            alt="support"
            className="object-scale-down h-24 w-60"
          />
          <img
            src="https://res.cloudinary.com/umuv/image/upload/v1636501496/Sublime%20Dance%20Company/REPERTORY/StepFallRise/teatro-cine-torres-vedrars-logo.jpg"
            alt="support"
            className="object-scale-down h-48 w-96"
          />
          <img
            src="https://res.cloudinary.com/umuv/image/upload/v1636502164/Sublime%20Dance%20Company/REPERTORY/StepFallRise/Screen_Shot_2021-11-09_at_11.55.34_PM.png"
            alt="support"
            className="object-scale-down h-48 w-96"
          />
        </div>
      </div>
    </div>
  );
}
