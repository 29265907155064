import ReactPlayer from "react-player";

const KutxiKutxiKutxi = () => {
  return (
    <div className="text-center">
      <h1 className="text-golden text-4xl py-20"> Kutxi Kutxi Kutxi</h1>

      <div className="flex justify-center">
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1644006956/Sublime%20Dance%20Company/REPERTORY/Kutxi%20Kutxi/illustration-vect-no-background-kutxi-kutxi-kutxi-andreiamelo.png"
          className="w-96"
          alt="kutxi poster"
        />
      </div>

      <div className="flex flex-col leading-relaxed items-center">
        <p className="leading-relaxed pt-20">SINOPSE:</p>
        <p className="leading-relaxed p-20 text-2xl xl:w-2/3 lg:w-full sm:w-full">
          KUTXI KUTXI KUTXI é uma peça de dança contemporânea para bebés,
          crianças e suas famílias. A peça será criada a pensar na alimentação e
          nutrição das crianças, trazendo para o palco uma refeição recheada de
          alimentos que se expressam de forma abstrata para criar um ambiente de
          fantasia onde dança, musica, sons, cores e formas atraem os
          espectadores. O nosso corpo ingere, desenvolve-se e move-se num mundo
          de descoberta.
        </p>
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1644006955/Sublime%20Dance%20Company/REPERTORY/Kutxi%20Kutxi/massa.png"
          className="py-20 w-96 ml-20"
          alt="kutxi pasta poster"
        />
        {/* <img
          src="https://res.cloudinary.com/umuv/image/upload/v1644006955/Sublime%20Dance%20Company/REPERTORY/Kutxi%20Kutxi/massa.png"
          className="py-20 w-80"
        /> */}
        <p className="leading-relaxed p-20 text-2xl lg:w-2/3 sm:w-full">
          Um corpo saudável, ágil e expressivo alimenta-se de frescura, valor
          nutricional e equilíbrio. Dançar é apenas natural quando o corpo é
          saudável. A água é um elemento constante neste prato de sabores. Um
          gourmet dançante para os olhos dos espetadores mais atentos, os nossos
          bebés! As crianças são seduzidas pela experiência cinestésica, visual
          e auditiva, enquanto que os pais refletem sobre o conceito da
          alimentação e nutrição.
        </p>
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1644006956/Sublime%20Dance%20Company/REPERTORY/Kutxi%20Kutxi/fruta.png"
          className="py-20 w-96 ml-20"
          alt="kutxi fruit poster"
        />
      </div>

      <ReactPlayer
        url="https://stream.mux.com/giWPC35lIxsVbu6l0144mVwFod8csDMQVLiUXM5RM3vQ.m3u8"
        controls
        width="100%"
        height="70%"
        className="py-10"
      />

      <div className="mt-20 mb-20">
        <p className="text-2xl text-golden py-4">FICHA ARTÍSTICA e TÉCNICA:</p>

        <p className="leading-relaxed py-2">Direção Artística: Diana Seabra</p>
        <p className="leading-relaxed py-2">Coreografia: Diana Seabra</p>
        <p className="leading-relaxed py-2">
          Assistência de Coreografia: Olsi Gjeci
        </p>
        <p className="leading-relaxed py-2">
          Interpretação: Anna Manetti, Hayley Walker, Mariana Romão,
        </p>
        <p className="leading-relaxed py-2">
          Rita Gaspar, Miguel Nogueira, Jonathan Taylor, Sofie Heyman
        </p>
        <p className="leading-relaxed py-2">Som: Miguel Amorim</p>
        <p className="leading-relaxed py-2">Luz: Filipa Romeu</p>
        <p className="leading-relaxed py-2">Design Figurinos: Vanessa Pessoa</p>
        <p className="leading-relaxed py-2">
          Confecção Figurinos: Rosário Balbi
        </p>
        <p className="leading-relaxed py-2">
          Design e confecção de adereços para as cabeças: Luís Stoffel
        </p>
        <p className="leading-relaxed py-2">
          Cenografia: Miguel Ricardo e LGS_CCC
        </p>
        <p className="leading-relaxed py-2">
          Registo em Vídeo e Edição: João Sanches
        </p>
        <p className="leading-relaxed py-2">Ilustração: Andreia Melo</p>
        <p className="leading-relaxed py-2">
          Orientação Nutricional: Mariana Barbosa
        </p>
        <p className="leading-relaxed py-2">Produção: Pedro Fidalgo Marques</p>
        <p className="leading-relaxed py-2">
          Apoios: Direção Geral das Artes, Câmara Municipal de Oeiras
        </p>
        <p className="leading-relaxed py-2">
          Premiere: March 12, 2022 @ 15:00 & 19:00
        </p>
        <p className="leading-relaxed py-2">
          Place: Auditorio Municipal Ruy De Carvalho, Carnaxide
        </p>
      </div>
      <h3 className="py-2 text-golden text-2xl mt-40">
        This project was supported by
      </h3>

      <div className="flex justify-center py-20">
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1647075363/Sublime%20Dance%20Company/logos/dgartes_logo.jpg"
          alt="support"
          className="object-scale-down h-48 w-96"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1647075618/Sublime%20Dance%20Company/logos/OV_horizontal_preto.degrade.png"
          alt="support"
          className="object-scale-down h-48 w-96"
        />
      </div>
    </div>
  );
};

export default KutxiKutxiKutxi;
