import React from "react";
import ReactPlayer from "react-player";

const ParteDeCoisaNenhuma = () => {
  return (
    <div className="flex justify-center flex-col items-center text-cente leading-10">
      <h1 className="text-golden text-4xl">Parte De Coisa Nenhuma</h1>
      <p className="w-1/2">
        A criação de Parte de Coisa Nenhuma, nasceu da colaboração entre a
        CERCIOEIRAS e a coreógrafa Diana Seabra para a criação de uma peça de
        dança inclusiva com pessoas com deficiência intellectual e bailarinos
        profissionais.
      </p>
      <p className="mt-20">
        <span className="text-golden px-2">Performers:</span>
        Olsi Gjeçi, Pedro Carvalho, Ricardo Henriques, Teresa Manjua, Vitor Hugo
        Afonso, Diana Carvalho
      </p>
      <p>
        <span className="text-golden px-2">Choreography:</span>
        Diana Seabra
      </p>
      <p>
        <span className="text-golden px-2">Music:</span>
        VOZ - Nadine Brás, PIANO - Giovanni Barbieri, SAXOFONE - Artur Mendes
      </p>
      <p>
        <span className="text-golden px-2">Costumes:</span>
        Jesus Roriz & Diana Seabra
      </p>
      <p>
        <span className="text-golden px-2">Lighting Designer:</span>
        Hugo Franco
      </p>
      <p>
        <span className="text-golden px-2">Premiere:</span>
        December 9, 2017
      </p>
      <p>
        <span className="text-golden px-2">Place:</span>
        Grande Auditório Fundação Museu Oriente, Lisbon, Portugal
      </p>
      <img
        className="py-40"
        alt="parte de coisa nenhuma group"
        src="https://res.cloudinary.com/umuv/image/upload/v1664755891/Sublime%20Dance%20Company/REPERTORY/Parte%20De%20Coisa%20Nenhuma/parte-de-coisa-nenhuma-poster.png"
      />
      <p className="py-10">
        O projeto foi co-financiado pelo Instituto Nacional de Reabilitação
        (INR) e a Gestão dos Direitos dos Artistas (GDA). O objetivo primordial
        do projeto prendeu-se com a valorização das competências artísticas das
        pessoas com deficiência e potencialização das suas capacidades criativas
        e performativas no domínio não verbal.
      </p>
      <p className="py-10">
        A apresentação final da peça reflecte apenas uma parte de todo um
        processo criativo em estúdio durante 2 meses, o qual foi documentado em
        vídeo e estará disponível para ser visionado em documentário. A
        exploração de movimento alternou-se entre a expressividade de um
        indivíduo singular e a expressividade de um grupo e como ambos se afetam
        mutuamente. As fortes relações interpessoais que foram construídas ao
        longo deste processo reforçaram a premissa de que o movimento, dança e a
        música são meios de comunicação potenciadores de uma compreensão
        interpessoal mútua e autêntica.
      </p>

      <div className="mt-20 mb-20">
        <ReactPlayer
          url="https://www.youtube.com/watch?v=Qra4xs7rBRU"
          controls
        />
      </div>
      <h3 className="py-2 text-golden text-2xl mt-40">
        This project was supported by
      </h3>
      <div className="flex flex-wrap justify-center py-20">
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1665443448/Sublime%20Dance%20Company/logos/cerci-oeiras.jpg"
          alt=" cerci oeiras - suppport"
          className="object-scale-down h-48 w-96"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1665443448/Sublime%20Dance%20Company/logos/fundacao_GDA.png"
          alt=" fundacao GDA- suppport"
          className="object-scale-down h-48 w-96"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1665443448/Sublime%20Dance%20Company/logos/instituto_nacional_rehabilitacao.jpg"
          alt="instituto nacional rehabilitacao - suppport"
          className="object-scale-down h-48 w-96"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1665443472/Sublime%20Dance%20Company/logos/logo_dighton.png"
          alt=" hotel dighton- suppport"
          className="object-scale-down h-48 w-96"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1665443472/Sublime%20Dance%20Company/logos/logo_simoldes.jpg"
          alt="Simoldes Group - suppport"
          className="object-scale-down h-48 w-96"
        />
        <img
          src="https://res.cloudinary.com/umuv/image/upload/v1665443472/Sublime%20Dance%20Company/logos/logo_museu_oriente.jpg"
          alt="  Fundacao Museo Oriente- suppport"
          className="object-scale-down h-48 w-96"
        />
      </div>
    </div>
  );
};

export default ParteDeCoisaNenhuma;
