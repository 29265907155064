export const filmData = [
  {
    title: "ELIXIR",
    description: [
      "Choreography: Olsi Gjeci",
      "Dancers: Diana Seabra and Olsi Gjeci",
      "Videography and Editing: Alex Wiczor",
      "Costumes: Diana Seabra",
      "Special Thanks to: Palácio Marquês do Pombal and Oeiras Dance Academy",
    ],
    url: "https://www.youtube.com/watch?v=5ChefJ7exJA",
  },
  {
    title: "TRAÇOS",
    description: [
      "TRAÇOS highlights architectural elements of a random space. The idea is to use our bodies as the missing pieces of the architectural complexity in this space. Movement brings form, life and energy to a space that otherwise is aimless.",
      "Alex Wiczor: Co-Director, Camera-man Video Editor and original Music",
      "Olsi Gjeci: Co-Director, Choreographer, Dancer",
      "Diana Seabra: Dancer",
    ],
    url: "https://www.youtube.com/watch?v=h7t9iftHJhU",
  },
  {
    title: "NONAC",
    description: [
      "Our latest collaboration with Axel Wiczorke",
      "Cinematography and Editing: Axel Wiczorke",
      "Dancers: Diana Seabra, Olsi Gjeci",
      "Choreography: Olsi Gjeci",
      "With the generous support from Oeiras Dance Academy and Palácio Marquês de Pombal. We would like to personall thank Pedro Fidalgo Marques and Celeste Gil respectively.",
    ],
    url: "https://www.youtube.com/watch?v=liCTf1Er01Y",
  },
  {
    title: "CUT",
    description: [
      "Sublime's second short dance movie in collaboration with the fantastic",
      "Alex Wiczor (video,photo), Miguel Amorim (original music)",
      "Carolina Branco and Diana Seabra: Dancers",
      "Olsi Gjeci: video editing and choreography",
    ],
    url: "https://www.youtube.com/watch?v=4u8w6DVxrwg",
  },
  {
    title: "Don't Drop The Ball",
    description: [
      "Alex Wiczor: Co-Director, Camera-man Video Editor & original Music",
      "Olsi Gjeci: Co-Director, Choreographer, Dancer",
      "Diana Seabra: Dancer",
    ],
    url: "https://www.youtube.com/watch?v=D8viH5iSk3c",
  },
  {
    title: "Females in the Palace",
    description: [
      "This dance-film is our first experiment of what it is intended to be as a bigger and more ambitious project with Palácio do Marquês de Pombal.",
      "DANCERS: Tatiana Verissimo Carolina Branco Krisztina Sessi CAMERA: Alex Wiczor",
      "MUSIC: Miguel Amorim",
      "MAKE-UP ARTIST: João Frizza",
      "CHOREOGRAPHY AND EDITING: Diana Seabra",
      "ARTISTIC DIRECTION: Olsi Gjeci",
      "WITH THE KIND SUPPORT OF: OEIRAS DANCE ACADEMY and PALÁCIO DO MARQUÊS DO POMBAL (OEIRAS)",
    ],
    url: "https://www.youtube.com/watch?v=hLvw9LTSIHo",
  },
  {
    title: "Lost And Found (ALMASOMA)",
    description: [
      "Our latest collaboration with Axel Wiczorke & Almasoma",
      "Editing - Diana Seabra",
      "Videography - Alex Wiczor",
      "Performance - Laura Grens",
      "Choreography: Diana Seabra",
      "Music - `A New Error` by Kai Schumacher",
      "This dance-film was a collaboration between Almasoma - Instituto Transpessoal in Lisbon and Sublime Dance Company. We are very happy to share the belief with Almasoma that movement and dance can be used to heal the mind and the soul as well as to promote one's search for purpose and aliveness.",
      "Ilja van de Griend from Almasoma challenged Sublime with this project and we are very thankful and honoured with the trust placed in us!",
    ],
    url: "https://www.youtube.com/watch?v=Bv70-onRx_M",
  },
];
