import React from "react";

const ContactUs = () => {
  return (
    <div className="flex flex-col py-20 p-20 justify-center items-center w-screen h-screen text-3xl leading-relaxed">
      <p>Send us an email at</p>
      <a className="text-golden" href="mailto:info@sublimedancecompany.com">
        info@sublimedancecompany.com
      </a>
      <br />
    </div>
  );
};

export default ContactUs;
