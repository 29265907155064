import React from "react";
import ReactPlayer from "react-player";
import { filmData } from "../data/film";

const Film = () => {
  return (
    <div className="flex flex-col py-20 p-20 justify-center items-center w-full h-full leading-relaxed text-center">
      {filmData.map(({ title, description, url }) => {
        return (
          <div className="flex flex-col mt-20 py-32 w-full h-full flex-wrap">
            <div>
              <h1 className="text-4xl py-2 text-golden">{title}</h1>
              {description.map((desc) => {
                return <p className="text-xl leading-10">{desc}</p>;
              })}
            </div>
            <div className="flex justify-center mt-20 text-center">
              <ReactPlayer url={url} controls />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Film;
