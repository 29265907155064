import { useEffect } from 'react';

export default function Hiring() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="flex flex-col py-20 p-20 justify-center items-center w-full h-full leading-relaxed text-center">
      <h1 className="text-4xl py-2 text-golden">Hiring now</h1>
      <p className="text-xl leading-10">
        We are always looking for new talent to join our team. Sublime is looking for:
      </p>
      <h2 className="text-golden">Assistente Produção e Comunicação Sublime</h2>

      <div
        className="calendly-inline-widget mb-20 w-full h-full"
        data-url="https://calendly.com/sublime-dance-company/assistente-producao-e-comunicacao"
        style={{ minWidth: '1400px', height: '660px' }}
      ></div>
    </div>
  );
}
