import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Spacer from "../components/Spacer";
gsap.registerPlugin(ScrollTrigger);

function MainImageBlock() {
  const mainImgRef = useRef();

  useEffect(() => {
    gsap.set(mainImgRef.current, { scale: 1 });

    gsap.to(mainImgRef.current, {
      opacity: 1,
      duration: 3,
      ease: "power2.out",
      scale: 1.1,
      scrollTrigger: { trigger: mainImgRef.current, start: "top 65%" },
    });
  }, []);

  return (
    <div
      className="flex flex-col items-center bg-main-photo xl:bg-cover xl:h-screen md:bg-contain sm:bg-contain sm:bg-no-repeat
       bg-center sm:h-[440px] md:h-[740px] w-screen justify-center"
      ref={mainImgRef}
    ></div>
  );
}

function IntroMessage() {
  const sdcRef = useRef();
  const aimRef = useRef();
  const buildRef = useRef();
  const desireRef = useRef();
  const drawsUsRef = useRef();

  useEffect(() => {
    [sdcRef, aimRef, buildRef, desireRef, drawsUsRef].forEach((ref) => {
      gsap.to(ref.current, {
        opacity: 1,
        duration: 1,
        y: 16,
        ease: "power2.out",
        scrollTrigger: { trigger: ref.current, start: "top 65%" },
      });
    });
  }, []);

  return (
    <>
      <h1
        className="text-8xl font-righteous text-center py-10 text-gray-700 opacity-0 mt-20"
        ref={sdcRef}
      >
        Sublime
      </h1>
      <h1
        className="text-8xl font-righteous text-center py-20 text-gray-700 opacity-0"
        ref={aimRef}
      >
        aims to explore, question
      </h1>
      <h1
        className="text-8xl font-righteous text-gray-700 text-center py-20 opacity-0"
        ref={buildRef}
      >
        and build upon,
      </h1>
      <h1
        className="text-8xl font-righteous text-gray-700 text-center py-20 opacity-0"
        ref={desireRef}
      >
        the essential desire
      </h1>

      <h1
        className="text-8xl font-righteous text-gray-700 text-center opacity-0"
        ref={drawsUsRef}
      >
        that constantly draws us to
      </h1>
    </>
  );
}

function ShallowText({ text }) {
  return (
    <>
      <div className="flex w-full flex-row justify-center text-center py-20">
        <p className="shallow-text absolute font-ultra text-8xl text-gray-700 font-righteous">
          {text}
        </p>
        <p className="clone absolute font-ultra text-8xl text-gray-700 font-righteous">
          {text}
        </p>
      </div>
    </>
  );
}

function Home() {
  return (
    <div className="">
      <MainImageBlock />
      <IntroMessage />
      <ShallowText text="MOVEMENT !" />
      <Spacer twUnit="py" amount="20" />
    </div>
  );
}

export default Home;
