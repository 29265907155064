import ReactPlayer from "react-player";

const PushItOnline = () => {
  return (
    <div className="overflow-hidden text-center">
      <h1 className="text-golden text-4xl py-20">Push It Online</h1>

      <div className="flex flex-col lg:w-full md:w-full sm:w-full justify-center items-center">
        <p className="py-4 text-2xl ">
          This piece is a reflection on our digital presence in today's world
          and how this presence impacts our identity. What responsibility
          entails creating this presence and what is the role of the body in the
          digital world. What is the role of the movement?
        </p>
        <p className="py-4 text-2xl">
          PUSH IT ONLINE investigates the search for meaning that haunts the
          human being. One meaning, a purpose, a goal - what binds us to this
          world that is constantly on? What kind of links? Will our identity be
          as volatile as a status in an online profile? What need is this to
          define ourselves in the eyes of a world in constant definition? A
          world that does not stop
        </p>

        <div className="grid xl:grid-cols-3 sm:grid-cols-1 gap-10 justify-center items-center mt-20 mb-20">
          {[
            "https://res.cloudinary.com/umuv/image/upload/v1674514168/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC03221.jpg",
            "https://res.cloudinary.com/umuv/image/upload/v1674514168/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC03226.jpg",
            "https://res.cloudinary.com/umuv/image/upload/v1674514168/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC03498.jpg",
            "https://res.cloudinary.com/umuv/image/upload/v1674514167/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC03200.jpg",
            "https://res.cloudinary.com/umuv/image/upload/v1674514167/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC03320.jpg",
            "https://res.cloudinary.com/umuv/image/upload/v1674514167/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC03662.jpg",
            "https://res.cloudinary.com/umuv/image/upload/v1674514167/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC03362.jpg",
            "https://res.cloudinary.com/umuv/image/upload/v1674514167/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC03229.jpg",
            "https://res.cloudinary.com/umuv/image/upload/v1674514167/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC03266.jpg",
            "https://res.cloudinary.com/umuv/image/upload/v1674514167/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC03168.jpg",
            "https://res.cloudinary.com/umuv/image/upload/v1674514167/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC01666.jpg",
            "https://res.cloudinary.com/umuv/image/upload/v1674514167/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC03185.jpg",
            "https://res.cloudinary.com/umuv/image/upload/v1674514166/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC03178.jpg",
            "https://res.cloudinary.com/umuv/image/upload/v1674514166/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC03181.jpg",
            "https://res.cloudinary.com/umuv/image/upload/v1674514166/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC03150.jpg",
            "https://res.cloudinary.com/umuv/image/upload/v1674514166/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC01596.jpg",
            "https://res.cloudinary.com/umuv/image/upload/v1674514166/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC01564.jpg",
            "https://res.cloudinary.com/umuv/image/upload/v1674514166/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC03157.jpg",
            "https://res.cloudinary.com/umuv/image/upload/v1674514166/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC01430.jpg",
            "https://res.cloudinary.com/umuv/image/upload/v1674514166/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC01581.jpg",
            "https://res.cloudinary.com/umuv/image/upload/v1674514649/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/poster.jpg",
          ].map((img) => (
            <img src={img} className="w-full" alt="Push It Online" />
          ))}
        </div>

        <div className="flex justify-center mt-20 mb-20 w-full">
          <ReactPlayer
            url="https://stream.mux.com/zVwC5Dh00WfhJ02I8pOnGtqKIBKX7DzlSiHGivWzcmTa8.m3u8"
            controls
            width="80%"
            height="80%"
          />
        </div>

        <p className="py-20 text-2xl">
          We live in a reality that transcends the data stored in the same
          reality. We live in the moment of diving, but we also live the photo,
          the video, the comment, sharing, opinions, of the dive. Diving wets
          our body and then floods our minds. A river of dice that doesn't stop
          flowing, dice that combine to urge us to be a certain way, to think a
          certain way and to accept ourselves according to what is actually
          accepted online.
        </p>
        <p className="py-20 text-2xl">
          The truth matters less and the novelty matters more. What risks do we
          take in set goals about fleeting things, things that don't stick, that
          easily they lose their meaning, they change course from one day to the
          next, from one second to the other. Are we needed as individuals? Or
          will we soon be expendable? One body, a relic, less efficient, more
          erroneous than a small computer, more likely to break, more emotional,
          less confident, a body! Just a body.
        </p>

        <div className="grid grid-cols-2 gap-4">
          <img
            src="https://res.cloudinary.com/umuv/image/upload/v1674514166/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC01430.jpg"
            alt="push it online"
          />

          <img
            src="https://res.cloudinary.com/umuv/image/upload/v1674514167/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC01666.jpg"
            alt="push it online"
          />

          <p className="py-8 text-2xl text-golden">
            PUSH IT ONLINE
            <br />
            <br />
            plays with stereotypes and deepens the superficiality of reputation
            online, while recognizing the dual power of this new way of being, a
            power that lifts and defeats us in a space of seconds.
          </p>

          <img
            src="https://res.cloudinary.com/umuv/image/upload/v1674514166/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC01596.jpg"
            alt="push it online"
          />

          <img
            src="https://res.cloudinary.com/umuv/image/upload/v1674514167/Sublime%20Dance%20Company/REPERTORY/Push%20It%20%20Online/2023-website-photos-updated/DSC03320.jpg"
            alt="push it online"
            className="col-span-2"
          />
        </div>
      </div>
    </div>
  );
};

export default PushItOnline;
