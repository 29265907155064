import gsap from 'gsap';
import { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';

const Header = () => {
  const menuRef = useRef();
  const [hidden, setHidden] = useState(false);

  function showMenu() {
    gsap.to(menuRef.current, { opacity: 1, ease: 'easeOut' });
    setHidden(false);
  }

  function hideMenu() {
    gsap.to(menuRef.current, { opacity: 0, ease: 'easeOut' });
    setHidden(true);
  }

  const menuItems = [
    { name: 'company', link: 'company' },
    { name: 'calendar', link: 'calendar' },
    { name: 'repertory', link: 'repertory' },
    { name: 'film', link: 'film' },
    // { name: "book us", link: "book-us" },
    { name: 'contact', link: 'contact-us' },
    { name: 'support', link: 'support-us' },
  ];

  return (
    <>
      <button
        className={`space-y-2 fixed top-4 right-4 cursor-pointer z-20 sm:opacity-1 lg:hidden`}
        onClick={hidden ? showMenu : hideMenu}
      >
        <div className="w-8 h-0.5 bg-gray-600 cursor-pointer x-10"></div>
        <div className="w-8 h-0.5 bg-gray-600 cursor-pointer x-10"></div>
        <div className="w-8 h-0.5 bg-gray-600 cursor-pointer x-10"></div>
      </button>

      <div
        className={`shadow-md bg-gray-100 justify-center fixed w-full lg:h-20 xl:h-20 z-10 ${
          hidden && 'sm:flex'
        }opacity-1 ${hidden ? 'hidden' : 'flex'}`}
        ref={menuRef}
      >
        <div className="fixed grow-0 left-2 top-2">
          <NavLink to="/">
            <img
              src="https://res.cloudinary.com/umuv/image/upload/v1665601924/Sublime%20Dance%20Company/logos/sdc-logo-transparent-bg.png"
              width="60px"
              height="60px"
              alt="sublime-dance-company-logo fill-white"
            ></img>
          </NavLink>
        </div>
        <ul className="flex items-center md:flex lg:flex-row sm:flex-col">
          {menuItems.map(({ name, link }) => (
            <li key={name} className="xl:m-6 m-2 text-xl text-golden hover:text-gray-500">
              <NavLink to={link}>{name.toLocaleUpperCase()}</NavLink>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Header;
