import Spacer from '../components/Spacer';
import { artists } from '../data/artists';

function Artist({ id, name, bio, photo /* bioLinks */ }) {
  return (
    <div className="flex flex-col lg:w-1/2 items-center sm:w-full mb-40">
      <img src={photo} className="w-60 rounded-full" style={{ shapeOutside: `circle(50%)` }} alt={`${name}`} />
      <h2 className="text-2xl mt-4 mb-10 text-golden">{name}</h2>
      <div className="mx-10">
        {bio.map(p => (
          <p className="leading-8 px-4 text-center py-2" key={p}>
            {p}
          </p>
        ))}
        {id === 'olsi-gjeci' ? (
          <p className="text-center">
            Olsi is founder of the global dance platform{' '}
            <a
              href="https://umuv.world/"
              target="_blank"
              rel="noreferrer"
              className="text-golden2 underline cursor-pointer"
            >
              UMUV
            </a>
          </p>
        ) : null}

        {/* {bioLinks.map(({ name, url }) => {

          return (
            <a href={url} target="_blank" rel="noreferrer" className="text-golden2" key={url}>
              {name}
            </a>
          );
        })} */}
      </div>
      <Spacer twUnit="m" amount="10" />
    </div>
  );
}

function Direction() {
  return artists.direction.map(({ id, name, bio, photo /* bioLinks */ }) => {
    return <Artist {...{ id, name, bio, photo /* bioLinks */ }} />;
  });
}

export function Dancers() {
  return artists.dancers.map(({ name, bio, photo }) => {
    return <Artist {...{ name, bio, photo }} />;
  });
}

function MainPageHeader({ title }) {
  return <h1 className="text-4xl py-20 text-golden text-center mt-10">{title}</h1>;
}

function Company() {
  return (
    <div className="bg-zinc-100 flex flex-col w-full justify-center items-center">
      <MainPageHeader title="DIRECTION" />
      <Direction />
      <MainPageHeader title="DANCERS" />
      <Dancers />
    </div>
  );
}

export default Company;
