import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <div
      id="error-page"
      className="flex bg-gradient-to-tr from-stone-400 to-stone-600 items-center justify-center h-screen"
    >
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <b>{error.statusText || error.message}</b>
      </p>
    </div>
  );
}
