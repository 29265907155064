import React from "react";

const SupportUs = () => {
  return (
    <div className="bg-zinc-100 flex flex-col py-20 p-20 justify-center items-center w-screen  h-screen text-3xl leading-relaxed ">
      <p>We appreciate your support!</p>
      <br />
      <br />
      <p>You can donate through our</p>
      <p>
        <code className="text-golden">IBAN: PT50 0010 0000 520323500018 9</code>
      </p>

      <br />
    </div>
  );
};

export default SupportUs;
