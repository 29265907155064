import { SiFacebook, SiYoutube } from "react-icons/si";
import { FaTwitter } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

export const Footer = () => {
  const socialIcons = [
    {
      name: "insta",
      href: "https://www.instagram.com/sublimedancecompany/",
      rel: "noopener",
      Cmp: () => (
        <AiFillInstagram className="text-golden text-lg m-4" size="1.5rem" />
      ),
    },
    {
      name: "fb",
      href: "https://www.facebook.com/SublimeDanceCompany/",
      rel: "noopener",
      Cmp: () => (
        <SiFacebook className="text-golden text-lg m-4" size="1.5rem" />
      ),
    },
    {
      name: "twit",
      href: "https://twitter.com/SublimeDance",
      rel: "noopener",
      Cmp: () => (
        <FaTwitter className="text-golden text-lg m-4" size="1.5rem" />
      ),
    },
    {
      name: "youtube",
      href: "https://www.youtube.com/user/sublimedancecompany",
      rel: "noopener",
      Cmp: () => (
        <SiYoutube className="text-golden text-lg m-4" size="1.5rem" />
      ),
    },
  ];

  return (
    <div className="bg-gray-100 py-2 sticky bottom-0 flex items-cennter justify-center">
      {socialIcons.map(({ name, href, rel, Cmp }) => (
        <a key={name} href={href} rel={rel} className="hover:scale-150">
          <Cmp />
        </a>
      ))}
    </div>
  );
};
