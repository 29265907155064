export const artists = {
  direction: [
    {
      id: 'diana-seabra',
      name: 'Diana Seabra',
      bio: [
        'Diana Seabra is a dancer, choreographer, dance movement therapist and teacher.',
        "Diana holds a bachelor's degree in Psychomotor Rehabilitation by FMH (Lisbon) and a Master of Science in Dance Movement Therapy by Pratt Institute (New York). She was awarded a full-scholarship by Fundação Calouste Gulbenkian and FLAD, and completed her master's with a diploma of Excellence in Academic Achievement.",
        'Diana danced with AVEIA Dance Company, Regina Nejman & Company, Kinetic Architecture, Filipe La Feria, Benvindo Fonseca, Marco Mercier, Jácome Filipe and has participated in cinematographic projects.',
        "As a choreographer, Diana is interested in exploring themes related to human existence using not only movement but also voice and other art expressions. Diana's works include dance pieces, documentaries, workshops and artistic residencies in the community where diversity and inclusion is embraced.",
        'Her teaching experience expands from technique to community work, from children to professional dancers, from artistic to therapeutic purposes.',
        'Alongside with her artistic career Diana works as a dance therapist in Lisbon seeing adults and teenagers in her mental health practice. She is the Portuguese delegate of the European Association of Dance Movement Therapy and a member of the Ethics Committee of the same association. She is also the President of the Assembly of Associação Portuguesa de Dança Movimento Terapia and a member of the American Dance Therapy Association. Diana taught cultural studies in dance in Faculdade Motricidade Humana (Lisbon) and teaches in the course “Dance and movement in psycotherapy” at Universidade de Coimbra. Diana frequently teaches workshops to all kinds of population. Diana is developing her own teaching and work method called in synch - out of synch.',
        'She is the artistic director of Sublime Dance Company, together with Olsi Gjeci, dancing and choreographing for the company.',
      ],
      photo: 'https://res.cloudinary.com/umuv/image/upload/v1664131534/Sublime%20Dance%20Company/Diana_Seabra.png',
    },
    {
      id: 'olsi-gjeci',
      name: 'Olsi Gjeci',
      bio: [
        'Olsi Gjeci is an Albanian/American choreographer, dancer and dance teacher. He began his professional career as a folk dancer and trained by his father, dancer & choreographer Guri Gjeci. Olsi dances baroque and balinese among other styles.',
        'In 2006, he moved to New York and studied dance and philosophy at Hunter College. He has been dancing with The New York Baroque Dance Company since 2011 and the Trisha Brown Dance Company since December 2013. He is also a member of the Boston Early Music Festival Dance Ensemble and Linda Tomko. He dances with Vicky Shick, among others, in New York.',
      ],
      photo: 'https://res.cloudinary.com/umuv/image/upload/v1664128135/Sublime%20Dance%20Company/og_bw.jpg',
      bioLinks: [{ name: 'UMUV', url: 'https://umuv.world' }],
    },
  ],
  dancers: [
    // {
    //   name: 'Mariana',
    //   bio: [
    //     'Mariana Romão was born in Portugal, 2000. She started dancing at the age of 9 in Academia de Danca de Alcobaça. At 14 she moved to Lisbon to study in the Dance School of the National Conservatory. She was taught by Irina Zavialova, Catarina Moreira and more and worked with Fabio Lopez, Miguel Ramalho and Lorand Zachar. After graduating, Mariana moved to Switzerland to work with Cinevox Junior Company, directed by Malou Leclerc and had the pleasure to perform pieces by Franz Broadman, Felix Dumeril and Martin Chaix.',

    //     'In 2019 she joined Theater Pforzheim in Germany as an apprentice and performed pieces by Guido Markovik, Damian Gmuer and Odbayar Batsuuri. When the season ended, she returned to Switzerland for a one month project with Marcel Leeman and after that she returned to Portugal where she worked with Cristina Pereira and Vasco Macide in Intranzyt.',

    //     'Now Mariana is in Portugal and very enthusiastic to be a part of Sublime Dance Company for the 21/22 season.',
    //   ],
    //   photo:
    //     'https://res.cloudinary.com/umuv/image/upload/v1633085666/Sublime%20Dance%20Company/Mariana_Roma%CC%83o.png',
    // },
    {
      name: 'Marco Olival',
      bio: [
        'Marco began his studies in 2011 at Escola de Dança do Funchal.  In 2018 completes the Secondary Dance Course at Ginasiano Escola de Dança, in Porto.',
        " He joined the project 'MARGEM' by Victor Hugo Pontes and, at the same time, attended Advanced Training in Interpretation and Choreographic Creation by Companhia Instável, in Porto.",

        'He danced “BOCA MURALHA” by Catarina Miranda, attended the 1st year of the Advanced Training Program in Contemporary Dance at Performact in Torres Vedras.',
        'In 2021 he joined Sublime Dance Company.',
      ],
      photo: 'https://res.cloudinary.com/umuv/image/upload/v1671463243/Sublime%20Dance%20Company/Marco_Olival.png',
    },
    // {
    //   id: 'rita',
    //   name: 'Rita',
    //   bio: [
    //     'Rita Gaspar was born in 1998 in Leiria, Portugal. In 2014, she enrolled in a professional course in contemporary dance at Jobra Educação where she studied different styles of dance, such as contemporary dance, jazz, hip hop and ballet. In 2016, while completing her studies, she joined Companhia Dancenter as a dance intern with the choreographer Liliana Garcia. In the following year, she started a BA - Bachelor"s Degree Program in Dance - at PERA - School of Performing Arts, in Cyprus. She performed in several pieces by different choreographers, such as Horácio Macuacua, Danae & Dionysios, Julia Brendel, Maria Doulgeri, Harry Koushos, Kay Krook, Dione Roach, Rafaela Sahyoun. She also had the opportunity to create and perform her own pieces, like “Had Enough?”, “Unveil” and "Affective Tenderness"',
    //     'She attended several workshops of different techniques such as Flying Low with Horacio Macuaca and Sabrina Gargano, Partnering, with Roser Espinosa and Guy Nader & Maria Campos, Puzzle Work, with Anton Lachky, GAGA with Ido Gidron, Akira Yoshida, Judith Sanchez Ruiz, Edivaldo Ernesto and many others. In 2020, her last academic year, she interned at Companhia Profissional Corpo for 3 months. During the summer of 2021, she graduated and presented an original creation, in collaboration with Giovana Sanchez, at the university she attended and at the Curtas festival in Angra do Heroísmo, on Terceira island. Recently, she joined the project “dust”, by Joana Borges, and is currently part of Sublime Dance Company as a professional dancer.',
    //   ],
    //   photo:
    //     'https://res.cloudinary.com/umuv/image/upload/v1664142826/Sublime%20Dance%20Company/Rita_Gaspar_Sublime_Dance_Company.jpg',
    // },
    // {
    //   name: "Sofie",
    //   bio: [
    //     "Sofie Heyman was born in Denmark, 1996. Dance has been her leading compass in life ever since she was a kid, and she danced ballet. From 7th grade, she got introduced to modern dance at the Royal Ballet School in Odense, Denmark, where she graduated in 2009. Following primary school, she directly started at the one-year pre-education at Copenhagen Contemporary Dance School led by the great directors: Morten Innstrand and Lotte Sigh. Afterward, she spent four incredible years at the Dance Grunduddannelse (DGU) - AMOK Akadamien for Modern dance & Classical Ballet - The Royal Ballet School In Odense, Denmark.",
    //     "After graduating in 2018, she decided to continue her dance journey and moved to Stockholm to study at Balettakademien in Stockholm. Having Ballet and Contemporary in her backpack, she now explored all different kinds of styles. A wide variety of Contemporary, Improvisation, Gaga, Jazz, Street dance, Commercial, and Ballroom dance. At her graduation performance, she had the pleasure to work with choreographs such as Mari Carrasco, Eytan Sivak, Lee Brummer, and Charlotta Öfverholm.",
    //     'Sofie"s biggest curiosity is to explore movement and use it as a tool to understand herself, her relationships, and the people around her. Sofie will be an intern at Sublime Dance Company from the 1st of October.',
    //   ],
    //   photo:
    //     "https://res.cloudinary.com/umuv/image/upload/v1664143007/Sublime%20Dance%20Company/Sofie_Heyman_Sublime_Dance_Company.png",
    // },
    {
      id: 'miguel',
      name: 'Miguel',
      bio: [
        'Miguel Santos holds a degree from the Superior School of Dance. He began his journey in dance at the age of 6 with the João de Deus Ballroom Dance Group. He joined the Algarve Dance Company.',
        'He is a licensed ballroom dance teacher by the IDTA (International Dance Teachers Association). He has worked and still collaborates with the Almada Dance Company, Évora Contemporary Dance Company, Paulo Ribeiro Company, Portuguese Contemporary Ballet Company, and Quorum Ballet.',
        "As a choreographer, he created the solo 'Almost Magical,' and another solo resulting from an intensive course at Eastman Productions in Antwerp. He co-created 'Desdemona' with Alexandra Fonseca and Fernanda Pereira. Currently, he is pursuing a Master's degree in Choreographic Creation and Professional Practices at the Superior School of Dance.",
        'He joined Sublime Dance Company in 2022.',
      ],
      photo:
        'https://res.cloudinary.com/umuv/image/upload/v1664143807/Sublime%20Dance%20Company/Miguel_Santos_Sublime_Dance_Company.jpg',
    },
    // {
    //   name: 'Joana Marques',
    //   bio: [
    //     'Nasceu em Lisboa em 1990. Iniciou a sua carreira na área do desporto tendo praticado ginástica rítmica de competição durante dez anos.',
    //     'Licenciada em Dança pela Escola Superior de Dança em 2012, esteve três anos como bailarina convidada pela Professora Irina Zavialova na Escola de dança do Conservatório Nacional. Fez ainda o curso modular F.O.R. dance theater da Olga Roriz.',
    //     'Trabalhou com Benvindo da Fonseca e Gonçalo Lobato pela Companhia Nós da Dança. Integrou como intérprete a Companhia de Afro-contemporâneo “Agadá” e posteriormente a Sublime Dance Company e a Vortice Dance Company, com quem trabalha como bailarina atualmente.',
    //     'Tirou o curso de pilates mat pela WellxProschool e a formação do autêntico pilates do estúdio da Inês Moreira (Método Romana’s Pilates), onde dá aulas atualmente.',
    //   ],
    //   photo:
    //     'https://res.cloudinary.com/umuv/image/upload/v1664143437/Sublime%20Dance%20Company/Joana-Marques_sublime-Dance_Company.jpg',
    // },
    // {
    //   name: 'Jonathan',
    //   bio: [
    //     'Born in the USA, Jonathan Taylor, dancer and performer, developed his skills in theatre and dance styles such as Hip-Hop, Modern Jazz, Classical Ballet and Contemporary. Graduated with honors in both high school as a visual arts student and in Escola Superior de Dança where he was awarded with the Best Graduated Student Award in 2019. The year before Taylor was also nominated for the Peggy Harrison Award at the International Theatre Dance Awards in Manchester, UK.',
    //     'As a dancer Jonathan worked with the Vortice Dance Company and choreographers: Amélia Bentes, Clara Andermatt, Liliana Garcia, Rafael Alvarez, Victor Hugo Pontes among others, and has been a part of publicity and television productions like the “A Máscara” tv show.',
    //     'Throughout the years he mentions Clara Andermatt, Victor Hugo Pontes, Marco da Silva Ferreira, Gallen Hooks, Diana Matos, Hamilton Evans, Anthony Lee, Mike Song, Hugo Marmelada, Miguel Ramalho, Sylvia Rijmer, Allan Falieri, Cristina Planas Leitão, Vasco Alves, Bruno Duarte, Barbara Griggi and Tom Colin as some of the choreographers who have marked his growing process as a dancer.',
    //   ],
    //   photo:
    //     'https://res.cloudinary.com/umuv/image/upload/v1664143991/Sublime%20Dance%20Company/Jonathan_Taylor_Sublime_Dance_Company.jpg',
    // },
    {
      id: 'sancha',
      name: 'Sancha',
      bio: [
        // 'Sancha Fonseca (15 de fevereiro de 2003, Lisboa) começou a dançar com 2 anos na Escola Vocacional de Dança de Caldas da Rainha. Aos 10anos ingressou na Escola Artística de Dança do Conservatório Nacional onde completou em 2021 a sua formação artística especializada em Dança. Teve como professores como: Liliana Mendonça, Cyrille de la Barre, Mikhail Zavialov e Cristina Pereira, entre outros. Seguidamente, entrou na Licenciatura em Dança na Escola Superior de Dança (ESD), conjugando ao mesmo tempo, o Quorum Project, da companhia Quorum Ballet. No ano seguinte, focou-se apenas no Quorum Project, onde teve oportunidade de colaborar com a Companhia e trabalhar, durante os dois anos, com os coreógrafos e professores Daniel Cardoso, Inês Pedruco, Miguel Esteves, Margarida Carvalho, Catarina Casqueiro, Gonçalo Lobato, Kim Potthoff, Tom Colin e Susana Matos. No presente ano, colabora com a Sublime Dance Company.',

        'Sancha Fonseca (February 15, 2003, Lisbon) started dancing at the age of 2 at the Caldas da Rainha Vocational School of Dance. At the age of 10, she entered the Artistic School of Dance at the National Conservatory, where she completed her specialized artistic training in Dance in 2021. Her teachers included Liliana Mendonça, Cyrille de la Barre, Mikhail Zavialov and Cristina Pereira, among others. She then started a degree in Dance at the Escola Superior de Dança (ESD), while also taking part in the Quorum Project of the Quorum Ballet company. The following year, she focused solely on the Quorum Project, where she had the opportunity to collaborate with the company and work within the two years with choreographers and teachers: Daniel Cardoso, Inês Pedruco, Miguel Esteves, Margarida Carvalho, Catarina Casqueiro, Gonçalo Lobato, Kim Potthoff, Tom Colin and Susana Matos. This year, she is collaborating with the Sublime Dance Company. ',
      ],
      photo: 'https://res.cloudinary.com/umuv/image/upload/v1700172549/Sublime%20Dance%20Company/Sancha_Fonseca.png',
    },
    // { name: "Eron", bio: [], photo: "" },
  ],
};
