import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './ErrorPage';
import Company from './pages/Company';
import Hiring from './pages/Hiring';
import Calendar from './pages/Calendar';
import Repertory from './pages/Repertory';
import Film from './pages/Film';
import BookUs from './pages/BookUs';
import ContactUs from './pages/ContactUs';
import SupportUs from './pages/SupportUs';
import Home from './pages/Home';
import PushItOnline from './pages/repertoryWork/PushItOnline';
import StepFallRise from './pages/repertoryWork/StepFallRise';
import TheFluidityOfThings from './pages/repertoryWork/TheFluidityOfThings';
import KutxiKutxiKutxi from './pages/repertoryWork/KutxiKutxiKutxi';
import ParteDeCoisaNenhuma from './pages/repertoryWork/ParteDeCoisaNenhuma';
import SixtyFourTimesPossibilities from './pages/repertoryWork/64Possibilities';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/company',
        element: <Company />,
      },
      {
        path: '/company/hiring',
        element: <Hiring />,
      },
      {
        path: 'calendar',
        element: <Calendar />,
      },
      {
        path: 'repertory',
        element: <Repertory />,
        children: [
          {
            path: 'push-it-online',
            element: <PushItOnline />,
            handle: { path: 'push-it-online' },
          },
          {
            path: 'step-fall-rise',
            element: <StepFallRise />,
            handle: { path: 'step-fall-rise' },
          },
          {
            path: 'kutxi-kutxi-kutxi',
            element: <KutxiKutxiKutxi />,
            handle: { path: 'kutxi-kutxi-kutxi' },
          },
          {
            path: 'the-fluidity-of-things',
            element: <TheFluidityOfThings />,
            handle: { path: 'the-fluidity-of-things' },
          },
          {
            path: 'parte-de-coisa-nenhuma',
            element: <ParteDeCoisaNenhuma />,
            handle: { path: 'parte-de-coisa-nenhuma' },
          },
          {
            path: '64x-possibilities',
            element: <SixtyFourTimesPossibilities />,
            handle: { path: '64x-possibilities' },
          },
        ],
      },
      {
        path: 'film',
        element: <Film />,
      },
      {
        path: 'book-us',
        element: <BookUs />,
      },
      {
        path: 'contact-us',
        element: <ContactUs />,
      },
      {
        path: 'support-us',
        element: <SupportUs />,
      },
    ],
  },
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}></RouterProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
